$(document).ready(function () {
    // tabs
    $(".tab-nav li").on("click", function () {
        var nav = $(this);
        var idx = nav.index();
        var wrap = nav.closest(".tabs");
        wrap.find(".tab-cont").removeClass("active");
        wrap.find(".tab-nav li").removeClass("active");
        wrap.find(".tab-cont:eq(" + idx + ")").addClass("active");
        nav.addClass("active");
    });

    // modal
    $(".modal-open").on("click", function () {
        var attr = $(this).attr("data-pop");
        $("#" + attr).fadeIn();
    });
    $(".modal-close").on("click", function () {
        $(".modal").fadeOut();
    });

    // fixed bg
    $(".fixed-bg").css("height", window.innerHeight);
});